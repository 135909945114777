import { Component} from '@angular/core';

@Component({
  selector: 'ai-app-component',
  templateUrl: './app.component.html'
})

export class AppComponent {
    
    constructor(){
    }
}
